html, body {
  background: #f4fff5;
  margin: 0;
  padding: 0;
  font-family: Shippori Antique B1, sans-serif;
  font-style: normal;
  font-weight: 400;
}

* {
  font-family: Shippori Antique B1, sans-serif;
  font-style: normal;
  font-weight: 400;
}

footer {
  text-align: center;
  background: #f4fff5;
  padding: 100px 0 50px;
}

footer h1 {
  display: none;
}

footer .sm {
  margin: 32px auto;
}

footer .sm a {
  margin: 0 32px;
}

footer .legal {
  color: #bfb598;
  font-size: 16px;
}

footer .zac {
  color: #bbb;
  margin-top: 32px;
  font-size: 12px;
}

/*# sourceMappingURL=styles.css.map */
