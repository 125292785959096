html, body {
    font-family: "Shippori Antique B1", sans-serif;
    font-weight: 400;
    font-style: normal;
    margin: 0;
    padding: 0;
    background: #F4FFF5;
}
* {
    font-family: "Shippori Antique B1", sans-serif;
    font-weight: 400;
    font-style: normal;
}
footer {
    background: #F4FFF5;
    text-align: center;
    padding: 100px 0 50px 0;
}
footer h1 {
    display: none;
}
footer .sm {
    margin: 32px auto;
}
footer .sm a {
    margin: 0 32px;
}
footer .legal {
    font-size: 16px;
    color: #BFB598;
}
footer .zac {
    margin-top: 32px;
    color: #BBB;
    font-size: 12px;
}
